import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color variable, de verd a marró oliva, encara que a vegades violaci, vermell fosc, porpra, etc. amb un capell de fins a 18 cm de diàmetre, que passa al llarg del seu estadi per diferents formes, globós al principi, convex per finalment ser pla, un poc deprimit al centre. Làmines molt fines, atapeïdes i quasi lliures, després més separades, amb bifurcacions prop del peu de color groc pàl·lid tornant terroses. El peu és quasi cilíndric, un poc llarg i robust. Ple, després moll i un poc rugós, de color blanc amb tons rosats o violacis. Les espores són de color groc or en massa, ovoïdals en grosses punxes o berrugues, de 8-12 x 7-10 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      